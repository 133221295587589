import React, { useEffect } from "react";
import Layout from "@herb/layouts";
import SEO from "@herb/components/seo";
import { deployUrl } from "@herb/utils";
import Image from "@herb/components/Image";
import "./index.css";
import { Link } from "@reach/router";
import { PrizePoolCard } from "../../components/Cards/PrizePoolCard";
import Slider from "react-slick";

const ScavengerHunt = () => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="prizePoolSliderArrowRight"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path
            d="M1 1L11 11L1 21"
            stroke="black"
            stroke-linecap="round"
            stroke-width="3"
          />
        </svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <svg
          className="prizePoolSliderArrowLeft"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          fill="none"
        >
          <path
            d="M1 1L11 11L1 21"
            stroke="black"
            stroke-linecap="round"
            stroke-width="3"
          />
        </svg>
      </div>
    );
  }
  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  };
  const prizes = [
    {
      sourceUrl:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/21111253/image.png"
    },
    {
      sourceUrl:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/21111259/image-3-Photoroom-1.png"
    },
    {
      sourceUrl:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/21111306/image-2-Photoroom-1.png"
    },
    {
      sourceUrl:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/21111259/image-3-Photoroom-1.png"
    },
    {
      sourceUrl:
        "https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/21111306/image-2-Photoroom-1.png"
    }
  ];
  const scrollHowToWin = event => {
    event.preventDefault();
    const target = document.getElementById("howToWin");
    const offset = window.innerHeight / 7;
    const elementPosition = target.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  };
  return (
    <div className="scavengerHuntMain">
      <Layout page="scavenger-hunt">
        <SEO
          title="The World's First Digital 4/20 Scavenger Hunt"
          description="Herb 420 Scavenger Hunt"
          url="/scavenger-hunt"
          canonical={`${deployUrl}/scavenger-hunt`}
          breadcrumbs={[]}
        />
        <div className="scavenger-hunt-content">
          <div className="hero-section">
            <div className="scavenger-hunt-text">
              <h1 className="scavenger-hunt-title">
                The World's First Digital 4/20 Scavenger Hunt
              </h1>
              <p className="scavenger-hunt-description">
                This 4/20, we're giving back to our fellow Herbivores.
                <a
                  className="descriptionSpan"
                  href="#howToWin"
                  onClick={event => scrollHowToWin(event)}
                >
                  {" "}
                  Here's how to win.
                </a>
              </p>
            </div>
            {/* <div className="sponsors">
              <a
                className="sponsor"
                href="https://indacloud.co/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/28111925/image-8.png" />
              </a>
              <a
                className="sponsor"
                href="https://viiahemp.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/28111835/VIIA-1.png" />
              </a>
            </div> */}
            <div className="scavenger-hunt-arrow">
              <img
                src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/10060413/down-arrow.png"
                alt="down-arrow"
              />
            </div>
          </div>
          <div className="eventDescriptionDiv container">
            <p className="eventDescription">
              Ready for the ultimate 4/20 event? Herb is officially launching
              the world's first-ever digital cannabis scavenger hunt, and you're
              invited to join the chase. No physical searching required—just a
              keen eye, your device, and your love for this stoner holiday.
            </p>
            <p>
              *There’s no purchase necessary, you must be 21+ to participate,
              multiple entries are encouraged, all prizes will be shipped within
              the continental United States*
            </p>
          </div>
          <div className="scavenger-hunt-cards container" id="howToWin">
            <div className="card1">
              <h3 className="instructionHeading">The Hunt Is On</h3>
              <p className="instructionDescription">
                Every day from{" "}
                <span className="instructionSpan">April 1-20</span>, we're
                releasing fresh content on{" "}
                <span className="instructionSpan">Herb.co</span> with a secret
                hiding spot for{" "}
                <span className="instructionSpan">our exclusive STICKERS</span>.
                Your mission? Track it down, click it, and rack up entries to
                win some of the most incredible prizes we've ever offered.
              </p>
              <div className="whereToLook">
                <h3 className="instructionHeading">Where To Look</h3>
                <ul>
                  <li className="instructionDescription">
                    Fresh articles dropping daily.
                  </li>
                  <li className="instructionDescription">
                    Our main website sections.
                  </li>
                  <li className="instructionDescription">
                    Every corner of Herb.co.
                  </li>
                </ul>
              </div>
            </div>
            <div className="card2">
              <img src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/21100549/Group-1000005846.png" />

              <p className="stickerText">
                <span>Each</span> STICKER you find and click ={" "}
                <span>1 entry</span> into our massive prize pool.
              </p>
            </div>
          </div>
          <div className="reveal-time container">
            <img src="../images/calender.svg" className="calenderImage" />
            <div className="timeDiv">
              <h3 className="reveal-heading">The Big Reveal</h3>
              <p className="reveal-date">4/20 at 4:20 PM PST</p>
            </div>
            <p className="reveal-description">
              Join us for our live Instagram broadcast (@Herb), where we'll
              announce ALL winners.
            </p>
          </div>
          <div className="brands-container">
            <div className="top-brands container">
              <a
                className="top-brand-item mcro"
                href="https://mcroedibles.com/?utm_source=herb&utm_medium=editorial&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Mcro.png" />
              </a>
              <a
                className="top-brand-item inda"
                href="https://indacloud.co/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/IndaCloud.png" />
              </a>
              <a
                className="top-brand-item viia"
                href="https://viiahemp.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/VIIAHemp.png" />
              </a>
              <a
                className="top-brand-item sunmed"
                href="https://getsunmed.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/sunmed.png" />
              </a>
              <a
                className="top-brand-item mitra"
                href="https://mitra-9.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/mitra9.png" />
              </a>
            </div>
            <div className="middle-brands">
              <a
                className="middle-brand-item stacheproducts"
                href="https://stacheproducts.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/stache.png" />
              </a>
              <a
                className="middle-brand-item wildorchardhemp"
                href="https://www.wildorchardhemp.com/all-products/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/WildOrchard.png" />
              </a>
              <a
                className="middle-brand-item lollisoda"
                href="https://www.lollisoda.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/lolli.png" />
              </a>
              <a
                className="middle-brand-item galaxytreats"
                href="https://galaxytreats.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/GalaxyTreats.png" />
              </a>
              <a
                className="middle-brand-item highrisebev"
                href="https://highrisebev.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/highrise.png" />
              </a>
              <a
                className="middle-brand-item hometownhero"
                href="https://hometownhero.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/hometown-hero.png" />
              </a>
            </div>
            <div className="bottom-brands container">
              <a
                className="bottom-brand-item yumz"
                href="https://yumzlab.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Yumz.png" />
              </a>
              <a
                className="bottom-brand-item paxBottom"
                href="https://www.pax.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/PAX.png" />
              </a>
              <a
                className="bottom-brand-item nwtn"
                href="https://nwtnhome.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/NWTN.png" />
              </a>
              <a
                className="bottom-brand-item mj"
                href="https://mjarsenal.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/MJArsenal.png" />
              </a>
              <a
                className="bottom-brand-item modus"
                href="https://moduslife.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Modus.png" />
              </a>
              <a
                className="bottom-brand-item alcapone"
                href="http://www.alcapone-us.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/AlCapone.png" />
              </a>
              <a
                className="bottom-brand-item binoidBottom"
                href="https://shop.binoidcbd.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Binoid.png" />
              </a>
              <a
                className="bottom-brand-item dyna"
                href="https://www.dynavap.com/?utm_source=Herb&utm_medium=Social&utm_campaign=Herb"
                target="_blank"
              >
                <Image src="./images/BrandLogos/DynaVap.png" />
              </a>
              <a
                className="bottom-brand-item hemplucid"
                href="https://hemplucid.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/HempLucid_Logo.png" />
              </a>
            </div>
            <div className="bottom-brands2">
              <a
                className="bottom-brand-item grenco"
                href="https://grencogoods.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/grenco.png" />
              </a>
              <a
                className="bottom-brand-item g-pen"
                href="https://www.gpen.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/g-pen.png" />
              </a>
              <a
                className="bottom-brand-item soul"
                href="https://soul.go2cloud.org/aff_c?offer_id=1&aff_id=1014&url_id=10&utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/SOUL.png" />
              </a>
              <a
                className="bottom-brand-item bong"
                href="https://bongpong.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herb&utm_id=bp1"
                target="_blank"
              >
                <Image src="./images/BrandLogos/BongPong.png" />
              </a>
              <a
                className="bottom-brand-item nail"
                href="https://e-nail.com/?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/nail.png" />
              </a>
              <a
                className="bottom-brand-item bloomz"
                href="https://bloomzhemp.com/?ref=herb&utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/Bloomz.png" />
              </a>
              <a
                className="bottom-brand-item cbx"
                href="https://www.cannabiotix.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/cbx.png" />
              </a>
              <a
                className="bottom-brand-item stundenglass"
                href="https://stundenglass.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/stundenglass.png" />
              </a>
              <a
                className="bottom-brand-item zenco"
                // href="https://stundenglass.com?utm_source=herb&utm_medium=420+landingpage&utm_campaign=herbco"
                target="_blank"
              >
                <Image src="./images/BrandLogos/zenco.png" />
              </a>
            </div>
          </div>
          <div className="prize-pool container">
            <Image
              src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/04/06064943/prize-pool.png"
              alt="The Prize Pool"
              className="prize-pool-image"
            />
            <div className="first-entry-btn-container">
              <Link
                to="/learn/cannabis-420-scavenger-hunt"
                className="first-entry-btn"
              >
                GET YOUR FIRST ENTRY HERE
              </Link>
            </div>
          </div>
          <div className="prize-pool-slider container">
            <Slider {...settings}>
              {prizes.map(prize => (
                <PrizePoolCard prize={prize} />
              ))}
            </Slider>
          </div>
          <div className="event-description container">
            <span>
              *Remember: This is a Herb-exclusive event. Nothing like this has
              ever been done in the cannabis space before. You're not just
              participating—you're making history with us.*
            </span>
          </div>
          <div className="social-links-container container">
            <div className="social-links">
              <a href="https://www.instagram.com/herb/" target="_blank">
                <Image
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/11175244/image-2.png"
                  alt="instagram"
                />
              </a>
              <a href="https://www.threads.net/@herb" target="_blank">
                <Image
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/11175242/image-3.png"
                  alt="threads"
                />
              </a>
              <a href="https://twitter.com/Herbworthy" target="_blank">
                <Image
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/11175241/image-4.png"
                  alt="twitter"
                />
              </a>
              <a href="https://www.facebook.com/herb" target="_blank">
                <Image
                  src="https://herb-platform-images.imgix.net/wp-content/uploads/2025/03/11175239/image-5.png"
                  alt="facebook"
                />
              </a>
            </div>
            <p className="social-text">
              Follow our socials for exclusive clues
            </p>
          </div>
          {/* <div className="sponsors-container">
            <p className="sponsor">Sponsor here</p>
          </div> */}
        </div>
      </Layout>
    </div>
  );
};
export default ScavengerHunt;
