import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./index.css";

const DescriptionForArticlePage = ({ item, showSticker }) => {
  const removeDataSaferedirecturl = / data-saferedirecturl="[^"]*"/g;
  let updatedContent = item.text?.replace(removeDataSaferedirecturl, "");

  const imageHTML =
    item?.addSticker && showSticker
      ? `<div class="textStickerContainer" id="text420StickerContainer">
    <img src="${item?.sticker420?.sourceUrl}" alt="420 Scavenger Hunt Sticker" style="max-width: 100%; height: auto;">
  </div>`
      : "";

  if (imageHTML) {
    let words = updatedContent.split(/\s+/);

    let middleIndex = Math.floor(words.length / 2);

    words.splice(middleIndex, 0, imageHTML);

    updatedContent = words.join(" ");
  }

  return (
    <div className="descriptionForArticle">
      <div className="descriptionTextP">{ReactHtmlParser(updatedContent)}</div>
    </div>
  );
};

export default DescriptionForArticlePage;
